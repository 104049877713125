import React from 'react'
import TextLink from '~/components/atoms/TextLink'
import TextCard from '~/components/molecules/TextCard'
import Image from '~/components/atoms/Image'

export default function IntroImagesParallax({key, index, ...props}) {

  const section = props.introWImagesParallaxing

  return (
    <section id={section.fieldGroupName + index}  className="pt-20 md:pt-36 bg-sand">
      <div className="container pt-20 lg:pt-36 relative">
        <div className="max-w-xl mx-auto mb-20 md:mb-0">
          <TextCard 
            headingClassName="text-h2"
            {...section.textCard}
          />
        </div>
      
        {section.images[0].image && (
          <div className="ml-auto block w-1/6 absolute lg:top-0 right-0">
            <div className="aspect-w-3 aspect-h-5">
              <Image data={section.images[0].image} className="!absolute w-full h-full" />
            </div>
          </div>
        )}
        {section.images[1].image && (
          <div className="mr-auto lg:-mt-12 block w-1/4">
            <div className="aspect-w-4 aspect-h-5">
              <Image data={section.images[1].image} className="!absolute w-full h-full" />
            </div>
          </div>
        )}
        {section.images[2].image && (
          <div className="mx-auto -mt-20 block w-1/3 transform translate-x-12 lg:translate-x-24">
            <div className="aspect-w-1 aspect-h-1">
              <Image data={section.images[2].image} className="!absolute w-full h-full" />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
